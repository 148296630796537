exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-dla-studenta-js": () => import("./../../../src/pages/blog-dla-studenta.js" /* webpackChunkName: "component---src-pages-blog-dla-studenta-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-edukacja-js": () => import("./../../../src/pages/edukacja.js" /* webpackChunkName: "component---src-pages-edukacja-js" */),
  "component---src-pages-index-copy-2-js": () => import("./../../../src/pages/index copy 2.js" /* webpackChunkName: "component---src-pages-index-copy-2-js" */),
  "component---src-pages-index-copy-js": () => import("./../../../src/pages/index copy.js" /* webpackChunkName: "component---src-pages-index-copy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kalkulator-js": () => import("./../../../src/pages/kalkulator.js" /* webpackChunkName: "component---src-pages-kalkulator-js" */),
  "component---src-pages-konkurs-js": () => import("./../../../src/pages/konkurs.js" /* webpackChunkName: "component---src-pages-konkurs-js" */),
  "component---src-pages-naszeblogi-js": () => import("./../../../src/pages/naszeblogi.js" /* webpackChunkName: "component---src-pages-naszeblogi-js" */),
  "component---src-pages-order-js": () => import("./../../../src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-paymentwaiting-js": () => import("./../../../src/pages/paymentwaiting.js" /* webpackChunkName: "component---src-pages-paymentwaiting-js" */),
  "component---src-pages-pobierzpolise-js": () => import("./../../../src/pages/pobierzpolise.js" /* webpackChunkName: "component---src-pages-pobierzpolise-js" */),
  "component---src-pages-policysent-js": () => import("./../../../src/pages/policysent.js" /* webpackChunkName: "component---src-pages-policysent-js" */),
  "component---src-pages-summary-js": () => import("./../../../src/pages/summary.js" /* webpackChunkName: "component---src-pages-summary-js" */),
  "component---src-pages-ubezpieczenie-dla-nauczyciela-js": () => import("./../../../src/pages/ubezpieczenie-dla-nauczyciela.js" /* webpackChunkName: "component---src-pages-ubezpieczenie-dla-nauczyciela-js" */),
  "component---src-pages-ubezpieczenie-dla-studenta-js": () => import("./../../../src/pages/ubezpieczenie-dla-studenta.js" /* webpackChunkName: "component---src-pages-ubezpieczenie-dla-studenta-js" */),
  "component---src-pages-ubezpieczenie-przedszkolaka-js": () => import("./../../../src/pages/ubezpieczenie-przedszkolaka.js" /* webpackChunkName: "component---src-pages-ubezpieczenie-przedszkolaka-js" */),
  "component---src-pages-ubezpieczenie-szkolne-js": () => import("./../../../src/pages/ubezpieczenie-szkolne.js" /* webpackChunkName: "component---src-pages-ubezpieczenie-szkolne-js" */),
  "component---src-pages-vlog-js": () => import("./../../../src/pages/vlog.js" /* webpackChunkName: "component---src-pages-vlog-js" */),
  "component---src-pages-zgloszenieszkody-js": () => import("./../../../src/pages/zgloszenieszkody.js" /* webpackChunkName: "component---src-pages-zgloszenieszkody-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

